import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

const selectORM = state => state.orm

const selectProducts = createSelector(
  selectORM,
  orm => orm.products
)

const selectStocks = createSelector(
  selectORM,
  orm => orm.stocks
)

const selectProductsAccessories = createSelector(
  selectORM,
  orm => orm.product_accessories
)

const selectOrders = createSelector(
  selectORM,
  orm => orm.orders
)

const selectCustomers = createSelector(
  selectORM,
  orm => orm.customers
)

const selectPlannings = createSelector(
  selectORM,
  orm => orm.plannings
)

const selectPlanningsByOrderId = createSelector(
  [
    selectPlannings,
    (state, orderId) => orderId
  ],
  (plannings, orderId) => plannings.getAll({ order_id: orderId })
)

const selectOrderById = createSelector(
  [
    selectOrders,
    (state, id) => id
  ],
  (orders, id) => orders.getById(id)
)

const selectOrdersByIdsAndType = createSelector(
  [
    selectOrders,
    (state, ids, _type) => ids,
    (state, ids, type) => type
  ],
  (orders, ids, type) => orders.getAll({ id: ids }).sortBy((order) => {
    return type === 'return' ? moment(order.return_at) : moment(order.pickup_at)
  })
)

const selectCustomersFromOrders = createSelector(
  [
    selectCustomers,
    (state, orders) => orders
  ],
  (customers, orders) => customers.getAll({ id: orders.pluck('customer_id') })
)

const selectProductsForIds = createSelector(
  [
    selectProducts,
    (state, ids) => ids
  ],
  (products, ids) => products.getAll({ id: ids })
)

const selectProductsAccessoriesForProductId = createSelector(
  [
    selectProductsAccessories,
    (state, productId) => productId
  ],
  (product_accessories, productId) => product_accessories.getAll({ product_id: productId, accessory_type: 'in_bag' })
)

export {
  selectProducts,
  selectStocks,
  selectProductsForIds,
  selectProductsAccessoriesForProductId,
  selectOrderById,
  selectOrdersByIdsAndType,
  selectPlanningsByOrderId,
  selectCustomersFromOrders
}
